import Head from 'next/head';

import { DynamicComponents } from '@/templates';

import { getPage, getPages } from '@/graphql/queries';
import { dynamicComponentsTransformer } from '@/graphql/transformers';

import type { WebPage, WithContext } from 'schema-dts';

const Generic = ({ page: { attributes } }: any) => {
  const schema: WithContext<WebPage> = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Nestar',
  };

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema),
          }}
        />
      </Head>
      {attributes?.Content?.map(
        ({ __typename, ...props }: any, index: number) => (
          <DynamicComponents
            type={__typename}
            {...props}
            key={`${__typename}-${index}`}
          />
        )
      )}
    </>
  );
};

export const getStaticProps = async ({ params, preview = false }: any) => {
  const { pages, menusMenus, preFooter } = await getPage(
    `/${params.slug?.at(0) ?? ''}`,
    preview
  );
  const attributes = pages.data[0]?.attributes || null;

  if (attributes?.Content)
    attributes.Content = dynamicComponentsTransformer(attributes.Content);

  return {
    props: {
      page: {
        theme: 'light',
        langcode: {
          id: 'es',
        },
        attributes,
        menu: menusMenus?.data || [],
        preFooter: preFooter?.data || [],
        preview,
      },
    },
    notFound: pages.data.length == 0,
    revalidate: process.env.NEXT_PUBLIC_REVALIDATE
      ? parseInt(process.env.NEXT_PUBLIC_REVALIDATE)
      : 1000,
  };
};

export async function getStaticPaths() {
  const { pages } = await getPages();

  return {
    paths: pages.data.map((itm: any) => itm.attributes.slug),
    fallback: 'blocking',
  };
}

export default Generic;
